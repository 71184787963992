import $ from 'jquery';
import { MESSAGE_INPUT } from '../constants';
import { isMobile } from '../utils';

const mobileListeners = () => {
  const textInput = $(MESSAGE_INPUT);
  // When mobile we make the text-input uneditable by default.
  // When the user clicks on it, we make it editable and give it focus.
  if (isMobile()) {
    textInput.attr('contenteditable', false);
    textInput.on('click', () => {
      textInput.attr('contenteditable', true);
      textInput.trigger('focus');
    });
  }
};

export default mobileListeners;
