/**
 * Generates a unique sender id that identifies the user
 */
export function generateUUID() {
  return `web-widget-${Math.random()
    .toString(36)
    .substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
}

export function preventFormatCopy() {
  document.addEventListener('copy', (e) => {
    const text_only = document.getSelection().toString();
    const clipdata = e.clipboardData || window.clipboardData;
    clipdata.setData('text/plain', text_only);
    clipdata.setData('text/html', text_only);
    e.preventDefault();
  });
}

export const getTimestamp = () => {
  return `${new Date().getHours()}:${
    new Date().getMinutes() < 10 ? '0' : ''
  }${new Date().getMinutes()}`;
};

export const isMobile = () => {
  if (
    window.innerWidth < 640 ||
    /iP(hone|od)|Android|BlackBerry|IEMobile|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
      navigator.userAgent,
    )
  ) {
    return true;
  }
  return false;
};

export const createError = (error) => {
  const err = new Error(error.message);
  err.code = error.code;
  return err;
};
