import './style.css';
// eslint-disable-next-line no-unused-vars
import regeneratorRuntime from 'regenerator-runtime';

import { injectChat } from './functions/injectChat';
import { defaultConfig } from './config';
import initEventListeners from './functions/initEventListeners';
import * as store from './store';
import { agent } from './agent';
import { createError } from './utils';

const fetchIntegrationData = async (host, id) => {
  const response = await fetch(`${host}/${id}`);
  const text = await response.text();
  const data = JSON.parse(text);

  if (response.ok) {
    return data;
  }
  throw createError(data);
};

export const init = async (userConfig) => {
  if (!userConfig || !userConfig.integrationId) {
    throw new Error(
      'integrationId is required.\n Call MoveoAI.init({ integrationId: YOUR_INTEGRATION_HERE })',
    );
  }
  const serverConfig = await fetchIntegrationData(
    userConfig.host || defaultConfig.host,
    userConfig.integrationId,
  );
  const integration = { ...defaultConfig, ...serverConfig, ...userConfig };

  store.setState(integration);

  injectChat(integration);
  initEventListeners();

  return agent;
};
