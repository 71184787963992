const moveoAudioMessage = (audio, avatar) =>
  [
    `<li class="moveo__other">`,
    `<img class="bot__image" src="${avatar}" />`,
    `<audio class="audio__bubble" controls="controls" title="${audio.title}" src="${audio.url}">`,
    `</audio>`,
    `</li>`,
  ].join('');

export default moveoAudioMessage;
