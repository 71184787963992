import { generateUUID } from './utils';

let state = null;
let context = {};
let senderId = generateUUID();
let chatHasBeenOpened = false;
let dialog = [];
let userMessageTime = null;

export const getState = () => state;
export const setState = (data) => {
  state = data;
};
export const getContext = () => context;
export const setContext = (data) => {
  context = data;
};
export const resetContext = () => {
  context = {};
};

export const getSenderId = () => senderId;
export const resetSenderId = () => {
  senderId = generateUUID();
};

export const getChatHasBeenOpened = () => chatHasBeenOpened;
export const setChatHasBeenOpened = (bool) => {
  chatHasBeenOpened = bool;
};

export const getDialog = () => dialog;
export const setDialog = (data) => {
  dialog = [...dialog, data];
};
export const resetDialog = () => {
  dialog = [];
};
export const getUserMessageTime = () => userMessageTime;

export const setUserMessageTime = (t) => {
  userMessageTime = t;
};
