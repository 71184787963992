import $ from 'jquery';
import { openChat } from './toggleChat';

import { initDropDownClickEvent } from './chatFunctions';
import { BUBBLE, ICON_BUBBLE_CLOSE, MOVEO } from '../constants';
import { handleBubble, handleBubbleClose } from './bubbleFunctions';
import { preventFormatCopy } from '../utils';
import mobileListeners from './mobileListeners';

const initEventListeners = () => {
  const bubble = document.querySelector(BUBBLE);
  const bubbleClose = document.querySelector(ICON_BUBBLE_CLOSE);

  if (bubble) {
    bubble.addEventListener('click', handleBubble);
  }
  if (bubbleClose) {
    bubbleClose.addEventListener('click', handleBubbleClose);
  }

  initDropDownClickEvent();

  const element = $(MOVEO);
  element.on('click', openChat);
  setTimeout(() => element.addClass('moveo__enter'), 1000);

  preventFormatCopy();
  mobileListeners();
};

export default initEventListeners;
