import { BOT_IMAGE } from './constants';

export const defaultConfig = {
  host: 'https://integrations.moveo.ai/v1/web',
  avatar: BOT_IMAGE,
  background_color: '#1b66d6',
  accent_color: '#FFFFFF',
  header_title: 'Customer Support',
  welcome_trigger_message: '',
  show_powered_by: true,
  window_size: 'regular',
  icon_size: 'regular',
};
