import { bubbleChat } from './bubbleFunctions';
import iconChat from '../templates/icon_chat';
import emptyMessages from '../templates/emptyMessages';
import renderBranding from '../templates/renderBranding';

export const injectChat = (integrationData) => {
  const {
    background_color,
    accent_color,
    header_title,
    welcome_message,
    show_powered_by,
    icon_size,
  } = integrationData;

  const widget = document.createElement('div');

  widget.innerHTML = `
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    </style>
    <div class="moveo">
      <i aria-hidden="true" class="icon__size-${icon_size}">
        ${bubbleChat(
          welcome_message,
          background_color,
          accent_color,
          icon_size,
        )}
        ${iconChat(background_color, icon_size)}
      </i>
      <div class="moveo__chat moveo__chat__container">
        <div class="moveo__header" style="background:${background_color}">
          <span class="moveo__title" style="color:${accent_color}">${header_title} <svg class="moveo__icon-online" width="9" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="4.5" cy="4.5" r="4.5" fill="#21D184"/></svg> </span>
          <div class="moveo__header__actions">
            <div id="moveo__action__menu" class="moveo__action__menu">
              <button class="moveo__dropdown__button">
                <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 8a3 3 0 100-6 3 3 0 000 6zM16 18.5a3 3 0 100-6 3 3 0 000 6zM16 29a3 3 0 100-6 3 3 0 000 6z" fill="#fff"/></svg>
              </button>
              <div id="moveo__dropdown" class="moveo__dropdown">
                <ul class="moveo__ul">
                  <li class="download__button">
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" stroke="#8499A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    Download Log
                  </li>
                  <li class="reset__button">
                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.875 3.667v5.5h5.25M20.125 18.333v-5.5h-5.25" stroke="#8499A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.929 8.25a8.28 8.28 0 00-2.192-3.414 7.754 7.754 0 00-3.494-1.88 7.53 7.53 0 00-3.926.121A7.809 7.809 0 004.935 5.17L.875 9.167m19.25 3.666l-4.06 3.997a7.809 7.809 0 01-3.382 2.093 7.53 7.53 0 01-3.926.121 7.754 7.754 0 01-3.493-1.88A8.281 8.281 0 013.07 13.75" stroke="#8499A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    Reset chat
                  </li>
                </ul>
              </div>
            </div>
            <div id="moveo__action__close" class="moveo__action__menu">
              <button class="moveo__button-close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>
            </div>
          </div>
        </div>
        <ul class="moveo__messages">
         ${emptyMessages()}
        </ul>
        <div class="moveo__footer">

          ${renderBranding(show_powered_by)}
          <div class="moveo__text-box" placeholder="Write a message" contenteditable="true" disabled="true"></div>
          <div class="footer__actions">
            <button type="button" class="moveo__send-message">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="${background_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
            </button>
            <span id="charcount" class="char__counter">280</span>
          </div>
        </div>
      </div>
    </div>`;

  document.body.appendChild(widget);
};
