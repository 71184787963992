import $ from 'jquery';
import {
  addMoveoResponses,
  addMoveoError,
  checkPauseDurationAndRender,
} from './moveoResponses';
import { MESSAGE_INPUT, MESSAGE_LIST } from '../constants';
import * as store from '../store';

import { agentEvents } from '../agent';
import { userTextMessage } from '../templates';

/**
 * Checks if the events are set by the user and returns new value if it exists
 * @param {string} value - Text message of the user
 * @param {string} event - Event declared by the user
 */
const runEventHandler = (value, event) => {
  let newValue;
  const eventExistsArray = agentEvents.filter((item) => item.event === event);

  if (eventExistsArray.length === 1) {
    newValue = eventExistsArray[0].handler(value);
  }

  if (!newValue) {
    newValue = value;
  }
  return newValue;
};

/**
 * Sends new message and returns Moveo response
 */
export const sendNewMessage = (text) => {
  const userInput = document.querySelector(MESSAGE_INPUT);
  const newText = runEventHandler(text, 'beforeMessageIsSent');
  if (!newText) {
    return;
  }
  addUserMessageBubble(newText);

  // show the typing indicator immediately after the user sends the message
  store.setUserMessageTime(Date.now());
  checkPauseDurationAndRender(false);

  const state = store.getState();
  const context = store.getContext();
  const senderId = store.getSenderId();
  const url = `${state.host}/${state.integrationId}`;

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: { text: newText },
      sender_id: senderId,
      channel: 'web',
      context,
    }),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      runEventHandler(text, 'afterMessageIsSent');
      store.setContext(resp.context);
      store.setDialog(resp);
      addMoveoResponses(
        resp.output.responses,
        state.avatar,
        state.background_color,
        state.accent_color,
      );
    })
    .catch((e) => {
      let error = e.responseText;
      try {
        error = JSON.parse(error);
      } catch (err) {
        console.error('Error parsing response', err);
      }
      console.error('Error calling Moveo', error);
      addMoveoError(error, state.avatar);
    });

  userInput.innerHTML = '';
  userInput.focus();
};

export const addUserMessageBubble = (text) => {
  const messagesContainer = $(MESSAGE_LIST);
  const { background_color, accent_color } = store.getState();
  $('.moveo__messages__empty').fadeOut(250);
  messagesContainer.append(
    userTextMessage(background_color, accent_color, text),
  );
  messagesContainer.fadeIn(250);
  $('.moveo__messages li:last').hide().fadeIn(250);
  messagesContainer
    .finish()
    .animate({ scrollTop: messagesContainer.prop('scrollHeight') }, 250);
};
