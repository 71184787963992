const bubble = (message, background_color, accent_color, icon_size) =>
  `
      <div class="moveo__bubble icon__size-${icon_size}" style="background:${background_color}">
        <p style="color:${accent_color}">${message}</p>
        <span>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.97974 2L5.95953 5.9999L1.97974 9.9998" stroke="${background_color}" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M10.0203 2.00024L6.04047 6.00015L10.0203 10" stroke="${background_color}" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </span>
      </div>
    `;

export default bubble;
