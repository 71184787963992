const iconChat = (color, icon_size) => {
  if (icon_size === 'large') {
    return `
      <svg class="moveo__icon-chat" width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40.5" cy="40" r="40" fill="${color}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M58.9403 55.3618C62.4115 51.1994 64.5 45.8436 64.5 40C64.5 26.7452 53.7548 16 40.5 16C27.2452 16 16.5 26.7452 16.5 40C16.5 53.2548 27.2452 64 40.5 64C44.8484 64 48.9268 62.8435 52.4442 60.8214C55.4142 62.514 58.5619 63.3056 60.896 63.6757C61.8986 63.8347 62.396 62.4577 61.6938 61.7246C60.5688 60.5502 59.4641 58.9668 59.152 57.0835C59.067 56.5708 58.9973 55.9887 58.9403 55.3618Z" fill="white"/>
        <rect x="25.5" y="44" width="5" height="8" rx="2.5" transform="rotate(-90 25.5 44)" fill="${color}"/>
        <rect x="35.5" y="44" width="5" height="8" rx="2.5" transform="rotate(-90 35.5 44)" fill="${color}"/>
        <rect x="45.5" y="44" width="5" height="8" rx="2.5" transform="rotate(-90 45.5 44)" fill="${color}"/>
        <rect x="25.5" y="36" width="5" height="10" rx="2.5" transform="rotate(-90 25.5 36)" fill="${color}"/>
        <rect x="25.5" y="51" width="5" height="10" rx="2.5" transform="rotate(-90 25.5 51)" fill="${color}"/>
        <circle cx="46.2114" cy="34" r="3" fill="${color}"/>
        <circle cx="39.5" cy="34" r="2" fill="${color}"/>
        <circle cx="53.2114" cy="34" r="2" fill="${color}"/>
      </svg>

      <svg class="moveo__icon-close" width="80" height="80" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="${color}"/>
        <path d="M29.2793 29.333L39.8921 39.9994L29.2793 50.6658" stroke="white" stroke-width="2.66667" stroke-linecap="round"/>
        <path d="M50.7207 29.3335L40.1079 39.9999L50.7207 50.6663" stroke="white" stroke-width="2.66667" stroke-linecap="round"/>
      </svg>
        `;
  }
  return `
      <svg class="moveo__icon-chat" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="${color}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.8302 41.5213C46.4337 38.3996 48 34.3827 48 30C48 20.0589 39.9411 12 30 12C20.0589 12 12 20.0589 12 30C12 39.9411 20.0589 48 30 48C33.2613 48 36.3201 47.1327 38.9581 45.6161C40.8267 46.6809 42.7891 47.2703 44.4063 47.5965C45.4015 47.7972 45.9276 46.4085 45.3006 45.6101C44.6893 44.8318 44.1671 43.8874 43.989 42.8126C43.9253 42.4281 43.873 41.9915 43.8302 41.5213Z" fill="white"/>
        <rect x="18.75" y="33" width="3.75" height="6" rx="1.875" transform="rotate(-90 18.75 33)" fill="${color}"/>
        <rect x="26.25" y="33" width="3.75" height="6" rx="1.875" transform="rotate(-90 26.25 33)" fill="${color}"/>
        <rect x="33.75" y="33" width="3.75" height="6" rx="1.875" transform="rotate(-90 33.75 33)" fill="${color}"/>
        <rect x="18.75" y="27" width="3.75" height="7.5" rx="1.875" transform="rotate(-90 18.75 27)" fill="${color}"/>
        <rect x="18.75" y="38.25" width="3.75" height="7.5" rx="1.875" transform="rotate(-90 18.75 38.25)" fill="${color}"/>
        <circle cx="34.2837" cy="25.5" r="2.25" fill="${color}"/>
        <circle cx="29.25" cy="25.5" r="1.5" fill="${color}"/>
        <circle cx="39.5337" cy="25.5" r="1.5" fill="${color}"/>
      </svg>

      <svg class="moveo__icon-close"width="60" height="60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60 30c0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30z" fill="${color}"/>
        <path d="M22.703 21.294a.994.994 0 00-1.41 0 1.006 1.006 0 000 1.417l7.253 7.291-7.254 7.291a1.006 1.006 0 000 1.418.994.994 0 001.41 0l7.336-7.372 7.335 7.373a.994.994 0 001.41 0c.39-.392.39-1.027 0-1.418l-7.253-7.291 7.254-7.291c.39-.392.39-1.026 0-1.418a.994.994 0 00-1.41 0l-7.336 7.372-7.335-7.372z" fill="#fff"/>
      </svg>
        `;
};

export default iconChat;
