import { BUBBLE } from '../constants';
import { bubble } from '../templates';

/**
 *Renders the bubble template if a welcome_message exists
 */
export const bubbleChat = (
  welcome_message,
  background_color,
  accent_color,
  icon_size,
) => {
  if (welcome_message) {
    return bubble(welcome_message, background_color, accent_color, icon_size);
  }
  return '';
};

export const handleBubble = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export const handleBubbleClose = () => {
  const bubbleNode = document.querySelector(BUBBLE);
  bubbleNode.classList.add('fadeOut-250');
  setTimeout(() => {
    bubbleNode.style.display = 'none';
  }, 250);
};
