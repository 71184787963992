const moveoFileMessage = ({ url, name }, avatar) =>
  `<li class="moveo__other">
  <img class="bot__image" src="${avatar}" />
  <a class="link__bubble" href="${url}" target="_blank">
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.44 11.05l-9.19 9.19a6.003 6.003 0 01-8.49-8.49l9.19-9.19a4.002 4.002 0 015.66 5.66l-9.2 9.19a2.001 2.001 0 11-2.83-2.83l8.49-8.48" stroke="#1B66D6 " stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>${name || url}
  </a>
  </li>`;

export default moveoFileMessage;
