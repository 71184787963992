const moveoVideoMessage = (video, avatar) =>
  [
    `<li class="moveo__other">`,
    `<img class="bot__image" src="${avatar}" />`,
    `<video class="video__bubble" controls="controls" title="${video.title}">`,
    `<source src="${video.url}" type="video/mp4">`,
    `<p>Your browser does not support the video tag.</p>`,
    `</video>`,
    `</li>`,
  ].join('');

export default moveoVideoMessage;
