import { openChat } from './functions/toggleChat';

export const agentEvents = [];

export const agent = {
  on: (event, handler) => {
    agentEvents.push({ event, handler });
  },
  open: (timeout) => {
    setTimeout(() => openChat(), timeout);
  },
};
