import $ from 'jquery';

import {
  BUBBLE,
  BUTTON_CLOSE,
  DOWNLOAD_BUTTON,
  DROPDOWN_BUTTON,
  ICON_CHAT,
  ICON_CLOSE,
  ICON_FILEUPLOAD,
  MESSAGE_INPUT,
  MESSAGE_LIST,
  MOVEO,
  RESET_BUTTON,
  SEND_MESSAGE_BUTTON,
} from '../constants';
import {
  handleDropdown,
  handleDownload,
  handleReset,
  handleFileUpload,
} from './chatFunctions';
import {
  _onChatInputKeyUp,
  _onChatInputKeyDown,
  _onSendMessageButtonClick,
} from './onChatInput';
import { isMobile } from '../utils';
import * as store from '../store';
import { sendNewMessage } from './sendNewMessage';
import mobileListeners from './mobileListeners';

const resizer = () => {
  const element = $(MOVEO);
  if (isMobile()) {
    element.addClass('moveo_mobile');
  } else {
    element.removeClass('moveo_mobile');
  }
};
/**
 * Open the chat bubble programmatically
 */
export const openChat = () => {
  const bubble = $(BUBBLE);
  const downloadButton = $(DOWNLOAD_BUTTON);
  const dropdownButton = $(DROPDOWN_BUTTON);
  const element = $(MOVEO);
  const iconChat = $(ICON_CHAT);
  const iconClose = $(ICON_CLOSE);
  const buttonClose = $(BUTTON_CLOSE);
  const iconFileUpload = $(ICON_FILEUPLOAD);
  const messages = $(MESSAGE_LIST);
  const resetButton = $(RESET_BUTTON);
  const textInput = $(MESSAGE_INPUT);
  const sendMessageButton = $(SEND_MESSAGE_BUTTON);

  mobileListeners();

  const state = store.getState();

  bubble.fadeOut(250);
  downloadButton.click(handleDownload);
  dropdownButton.click(handleDropdown);
  element.addClass('moveo__expand');
  element.addClass(`size-${state.window_size}`);
  element.find('.moveo__chat').addClass('moveo__enter');
  element.off('click', openChat);
  iconChat.animate({ opacity: '0' }, 250);
  iconClose.animate({ opacity: '1' }, 250);
  iconClose.on('click', closeChat);
  buttonClose.on('click', closeChat);
  iconFileUpload.click(handleFileUpload);
  resetButton.click(handleReset);
  textInput.keydown(_onChatInputKeyDown).prop('disabled', false).focus();
  textInput.keyup(_onChatInputKeyUp).prop('disabled', false).focus();
  sendMessageButton.on('click', _onSendMessageButtonClick);

  messages.scrollTop(messages.prop('scrollHeight'));
  // if the chat has never been opened, then send the welcome triggering message (if any)
  if (store.getChatHasBeenOpened() === false) {
    store.setChatHasBeenOpened(true);
    if (state.welcome_trigger_message) {
      sendNewMessage(state.welcome_trigger_message);
    }
  }
  if (isMobile()) {
    element.addClass('moveo_mobile');
    window.scrollTo(0, window.innerHeight);
  } else {
    element.removeClass('moveo_mobile');
  }
  $(window).on('resize', resizer);
};

/**
 * CLose the chat bubble programmatically
 */
export const closeChat = () => {
  const state = store.getState();

  const dropdownButton = $(DROPDOWN_BUTTON);
  const downloadButton = $(DOWNLOAD_BUTTON);
  const resetButton = $(RESET_BUTTON);

  const element = $(MOVEO);
  const iconChat = $(ICON_CHAT);
  const iconClose = $(ICON_CLOSE);

  $(window).off('resize', resizer);
  dropdownButton.off('click', handleDropdown);
  downloadButton.off('click', handleDownload);
  resetButton.off('click', handleReset);

  element.find('.moveo__chat').addClass('moveo__a-close');
  iconChat.animate({ opacity: '1' }, 250).off('click');
  iconClose.animate({ opacity: '0' }, 250).off('click', closeChat);
  element
    .find(MESSAGE_INPUT)
    .off('keydown', _onChatInputKeyDown)
    .off('keyup', _onChatInputKeyUp)
    .prop('disabled', true)
    .trigger('blur');
  setTimeout(() => {
    element
      .find('.moveo__chat')
      .removeClass('moveo__enter')
      .removeClass('moveo__a-close');
    element.click(openChat);
    element.removeClass('moveo__expand');
    element.removeClass(`size-${state.window_size}`);
  }, 250);
};
