import { getState } from '../../store';

const moveoTyping = () => {
  const { avatar, background_color } = getState();
  return `<li id="typing" class="moveo__other typing">
  <img class="bot__image" src="${avatar}" />
  <div class="moveo__loader">
  <div class="moveo__dot moveo__dot-1" style="background:${background_color}"></div>
  <div class="moveo__dot moveo__dot-2" style="background:${background_color}"></div>
  <div class="moveo__dot moveo__dot-3" style="background:${background_color}"></div>
  </div>
  </li>`;
};

export default moveoTyping;
