import { sendNewMessage } from './sendNewMessage';
import { MESSAGE_INPUT, MESSAGE_LENGTH } from '../constants';

export const _onChatInputKeyDown = (e) => {
  const charCode = typeof e.which === 'number' ? e.which : e.keyCode;
  const text = e.target.textContent;

  if (text.length >= MESSAGE_LENGTH && charCode !== 8) {
    e.target.textContent = text.substring(0, MESSAGE_LENGTH);
    e.preventDefault();
  }

  if (charCode === 13) {
    e.preventDefault();
    if (text.length) {
      sendNewMessage(text.trim());
    }
  }
};

export const _onSendMessageButtonClick = () => {
  const textInput = document.querySelector(MESSAGE_INPUT);
  const text = textInput.textContent;
  if (text.length) {
    sendNewMessage(text.trim());
  }
};

export const _onChatInputKeyUp = (e) => {
  charCount(e.target.textContent.length);
};

const charCount = (str) => {
  const lng = MESSAGE_LENGTH - str;
  document.getElementById('charcount').innerHTML = `${lng}`;
};
