/* eslint-disable operator-linebreak */
export const BOT_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyIDE2YzAgOC44MzctNy4xNjMgMTYtMTYgMTZTMCAyNC44MzcgMCAxNiA3LjE2MyAwIDE2IDBzMTYgNy4xNjMgMTYgMTZ6IiBmaWxsPSIjRjNGM0YzIi8+PG1hc2sgaWQ9ImEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjAiIHk9IjAiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PHBhdGggZD0iTTMyIDE2YzAgOC44MzctNy4xNjMgMTYtMTYgMTZTMCAyNC44MzcgMCAxNiA3LjE2MyAwIDE2IDBzMTYgNy4xNjMgMTYgMTZ6IiBmaWxsPSIjRjNGM0YzIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjYSkiIGZpbGw9IiNEMUQxRDEiPjxwYXRoIGQ9Ik0yMi40IDEyYTYuNCA2LjQgMCAxMS0xMi44IDAgNi40IDYuNCAwIDAxMTIuOCAwek0xNiAyMi40Yy02LjYyNyAwLTEyIDQuNTEzLTEyIDEwLjA4IDAgMCAyLjI1IDQuMzIgMTIgNC4zMnMxMi00LjMyIDEyLTQuMzJjMC01LjU2Ny01LjM3My0xMC4wOC0xMi0xMC4wOHoiLz48L2c+PC9zdmc+';
export const BUBBLE = '.moveo__bubble';
export const DOWNLOAD_BUTTON = '.download__button';
export const DROPDOWN = '.moveo__dropdown';
export const DROPDOWN_BUTTON = '.moveo__dropdown__button';
export const ICON_BUBBLE_CLOSE = '.moveo__bubble span';
export const ICON_CHAT = '.moveo__icon-chat';
export const ICON_CLOSE = '.moveo__icon-close';
export const BUTTON_CLOSE = '.moveo__button-close';
export const ICON_FILEUPLOAD = '.moveo__icon_upload';
export const MESSAGE_INPUT = '.moveo__text-box';
export const MESSAGE_LENGTH = 280;
export const MESSAGE_LIST = '.moveo__messages';
export const MOVEO = '.moveo';
export const RESET_BUTTON = '.reset__button';
export const SEND_MESSAGE_BUTTON = '.moveo__send-message';
export const DEFAULT_PAUSE = 1500;
