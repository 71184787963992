import { saveAs } from 'file-saver';
import { DROPDOWN, MESSAGE_LIST } from '../constants';
import emptyMessages from '../templates/emptyMessages';
import { resetContext, resetSenderId, getDialog, resetDialog } from '../store';

export const initDropDownClickEvent = () => {
  const dropdown = document.getElementById('moveo__action__menu');
  document.addEventListener('click', (event) => {
    const isClickInside = dropdown.contains(event.target);

    if (
      !isClickInside &&
      document.querySelector(DROPDOWN).classList.contains('open')
    ) {
      handleDropdown();
    }
  });
};

export const handleDropdown = () => {
  const dropdown = document.querySelector(DROPDOWN);

  if (dropdown.classList.contains('open')) {
    dropdown.classList.remove('open');
    dropdown.classList.add('fadeOut-200');
    dropdown.classList.remove('fadeIn-200');
  } else {
    dropdown.classList.add('open');
    dropdown.classList.add('fadeIn-200');
    dropdown.classList.remove('fadeOut-200');
  }
};

export const handleDownload = () => {
  const fileName = `${Date.now()}-moveo-webchat.json`;

  const fileToSave = new Blob([JSON.stringify(getDialog())], {
    type: 'application/json',
    name: fileName,
  });

  saveAs(fileToSave, fileName);
  handleDropdown();
};

export const handleReset = () => {
  const messagesContainer = document.querySelector(MESSAGE_LIST);
  messagesContainer.innerHTML = emptyMessages();
  resetContext();
  resetSenderId();
  handleDropdown();
  resetDialog();
};

export const handleFileUpload = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.click();
  return false;
};
