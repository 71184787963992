import anchorme from 'anchorme';

const moveoTextMessage = (
  { text, options },
  avatar,
  background_color,
  accent_color,
) => {
  let messages = [
    '<li class="moveo__other">',
    `<img class="bot__image" src="${avatar}" />`,
    anchorme({
      input: text,
      // use some options
      options: {
        attributes: {
          target: '_blank',
          class: 'detected',
        },
      },
      // and extensions
      extensions: [
        // an extension for greek + usa phones
        {
          test: /(0030|\+30)?(210\d{7}|^69\d{8}) | (\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/gi,
          transform: (string) => `<a href="tel:${string}">${string}</a>`,
        },
      ],
    }),
    '</li>',
  ];
  if (options && options.length > 0) {
    messages = [
      ...messages,
      '<li class="moveo__options">',
      '<ul class="moveo__ul">',
      options
        .map(
          (o) =>
            `<li class='messageOption' data-text="${o.text}" style="background:${accent_color};border:1px solid ${background_color};color:${background_color}" >${o.label}</li>`,
        )
        .join(''),
      '</ul>',
      '</li>',
    ];
  }

  return messages.join('');
};

export default moveoTextMessage;
